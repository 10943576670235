.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 94%;
    height: 900px;
    padding: 30px;
    min-width: 300px;
    border-radius: 8px;
    padding-top: 21px;
}

.containerAllele {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 35%;
    padding: 30px;
    min-width: 400px;
    border-radius: 8px;
    padding-top: 21px;
    justify-content: center;
    display: flex;
    align-content: center;
    flex-direction: column;
    min-width: 400px;
}

.allele {
    border: 1px solid #263b48;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13.5px;
}

.optionModalMutation {
    border: 1px solid #263b48;
    border-radius: 5px;
    cursor: pointer;
    padding: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13.5px;
}

.optionModalMutation:hover {
    background-color:  #FF7B54;
}
/* 
.addMutationButton{
    margin-bottom: 20px;
} */

.alleleTitle {
    font-weight: bold;
    font-size: 15px;
    margin-top: 1.5%;
    align-self: center;
}

.alleleDivs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 65%;
    align-self: center;
    margin-top: 5.5%;
}

.alleleCloseIcon {
    position: absolute;
    top: 6px;
    right: 9px;
    cursor: pointer;
}

.container0 {
    display: flex;
    flex-direction: column;
    width: 115px;
    min-width: 115px;
    justify-content: flex-end;
    margin-left: 38px;
}

.checkbox {
    max-height: 20px;
    width: 38px;
}

.chromPos {
    display: flex;
    flex-direction: row;
    background-color: #96f68a;
    font-weight: bold;
    width: 100%;
    border: 1px solid grey;
}

.borderContainer {
    border-left: 1px solid grey;
    margin-left: 5%;
}

.chromContainer {
    width: 45px;
    text-align: center;
    padding-left: 2px;
}

.posContainer {
    margin-left: 5%;
}

.headerDiv {
    display: flex;
    flex-direction: column;
    min-width: 85.5px;
    width: 85.5px;
    align-self: center;
    margin-left: 5px;
}

.headerDivQualityCoverage {
    display: flex;
    flex-direction: column;
        min-width: 118.15px;

    align-self: center;
    margin-left: 5px;
    max-width: 100%;
}

.headerDivQualityCoverageStep1 {
    display: flex;
    flex-direction: column;
    min-width: 118.15px;
    width: 118.5px;
    align-self: center;
    margin-left: 5px;
    max-width: 100%;
}

.roleNameDiv {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    height: 45px;
    overflow: hidden;
    white-space: normal;
}

.p1Div {
    background-color: #ff7b54;
    border-left: 1px solid grey;
    text-align: center;
    width: 100%; /* Cuando qualityCOverage esta activo bajar a 50*/
}

.p2Div {
    background-color: #96f68a;
    align-self: center;
    text-align: center;
    width: 100%;
}

.pQu {
    background-color: #96f68a;
    width: 50%;
    align-self: center;
    text-align: center;
    border-left: 1px solid grey;
}

.pContainer0 {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin-top: 5%;
}

.pContainer1 {
    flex-direction: row;
    display: flex;
    align-self: center;
    border: 1px solid grey;
    width: 100%;
}

.subContainerQC {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.subContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.footerContainer {
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; /* Agrega un margen superior para separación si es necesario */
}

.analizeByDiv {
    flex: 1;
    text-align: left;
}

.validateByDiv {
    flex: 1;
    text-align: center;
}

.buttonDiv {
    flex: 1;
    text-align: right;
}

.a1Word {
    width: 50%;
    align-self: center;
    text-align: center;
}

.a2Word {
    width: 50%;
    text-align: center;
}

.quaco {
    width: 50%;
    align-self: center;
    text-align: center;
    font-size: 10.5px;
    height: 24.5px;
}

.quacoR {
    width: 50%;
    align-self: center;
    text-align: center;
    font-size: 10.5px;
    height: 24.5px;
}

.dataContainer2 {
    width: 115px;
    min-width: 115px;
}

.dataContainer:hover {
    background-color: lightgrey;
}
.dataContainerCondition {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    min-width: 1200px;
    background-color: wheat;
}

.dataContainer5 {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    height: 100%;
}

.chromDiv2 {
    width:45px;
    text-align: center;
    align-self: center;
    padding-left: 3px;
    font-size: 12px;
}

.posDiv2 {
    margin-left: 5px;
    text-align: center;
    align-self: center;
    font-size: 11.5px;
    width: 52.5px;
}

.dataContainerBorder {
    border-left: 1px solid grey;
    margin-left: 5px;
}

.divFilters {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    justify-content: space-around;

}
.toggleOn{
    color: #6BBBDB;

}
.toggleOff{
    color: #8E8E8E;
}
.divToggles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: auto;
    
    
}
.filterColumn{
    display: flex;
    flex-direction: column;
    width: 33%;

}
.filterColumn2{
    display: flex;
    flex-direction: column;
    width: 33%;

}
.filterColumn3{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33%;

}
.headContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

}
.headerColumn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.legendDiv {
    margin-top: 1.5%;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.5%;
    width: 60%;
    margin-right: 20px;
}

.embryoChecked {
    font-size: smaller;
    border: 2px solid #6BBBDB;
    border-radius: 30px;
    cursor: pointer;
    flex-direction: row;
    display: flex;
    margin: 5.5px;
    padding: 4px;
    align-items: center;
}

.embryoNotChecked {
    font-size: smaller;
    border: 2px solid #8E8E8E;
    border-radius: 30px;
    cursor: pointer;
    flex-direction: row;
    display: flex;
    margin: 5.5px;
    padding: 4px;
    align-items: center;

}

.labelChecked {

    padding-right: 10px;
    color: #6BBBDB;
    margin-left: 3.5%;
    min-width: fit-content;


}

.labelNotChecked {
    padding-right: 10px;
    color: #8E8E8E;
    margin-left: 3.5%;
    min-width: fit-content;
}

.iconChecked {
    color: #6BBBDB;
    align-self: center;
    margin-right: 2%;
}

.iconNotChecked {
    color: #8E8E8E;
    align-self: center;
}

.dataContainer {
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.overflows {
    overflow-x: auto;
    height: 100%;
}

.container2 {
    display: flex;
    flex-direction: row;
    margin-top: 2.5%;
    white-space: nowrap;
}

.headerFixed1 {
    display: flex;
    flex-direction: column;
    min-width: 85.5px;
    position: sticky;
    left: 0;
    background-color: white;
    margin-left: 5px;
}

.headerFixed1QualityCoverage {
    display: flex;
    flex-direction: column;
    min-width: 375px;
    position: sticky;
    left: 0;
    background-color: white;
    margin-left: 5px;
}

.headerFixed1QualityCoverageStep1 {
    display: flex;
    flex-direction: column;
    min-width: 118.15px;
    position: sticky;
    left: 0;
    background-color: white;
    margin-left: 5px;
}

.headerFixed2 {
    display: flex;
    flex-direction: column;
    min-width: 90.5px;
    position: sticky;
    left: 80px;
    background-color: white;
    padding-left: 5px;
}

.headerFixed2QualityCoverage { /*fixme*/
    display: flex;
    flex-direction: column;
    width: 375px;
    position: sticky;
    left: 125px;
    background-color: white;
    margin-left: 5px;
}

.headerFixed2QualityCoverageStep1 {
    display: flex;
    flex-direction: column;
    min-width: 124.15px;
    position: sticky;
    left: 118px;
    background-color: white;
    padding-left: 5px;
}

.headerHidden {
    display: none;
}

.fixed1 {
    position: sticky;
    left: 0;
    z-index: 10;
    margin-left: 5px;
}

.fixed1Hiden {
    position: sticky;
    left: 0;
    z-index: 10;
    margin-left: 0px;
}



.fixed2 {
    position: sticky;
    left: 80px;
    z-index: 10;
    padding-left: 5px;
}

.fixed2WithQualityCoverage {
    position: sticky;
    left: 118px;
    background-color: white;
    z-index: 10;
    padding-left: 5px;
}

.fixed2WithQualityCoverageHiden {
    position: sticky;
    left: 118px;
    background-color: white;
    z-index: 10;
    padding-left: 0px;
}

.wordContainer2 {
    min-width: 85.5px;
    flex-direction: row;
    display: flex;
    align-self: center;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    width: 100%;
    
}

.wordContainer2QC {
    min-width: 118.15px;
    flex-direction: row;
    display: flex;
    align-self: center;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
}

.withoutSpaces {
    margin-left: 5px;
}

.withoutSpacesHiden {
    margin-left: 0px;
}

.scrollableContainer {
    max-width: 100%;
    max-height: 452px;
    overflow: visible !important;
    margin-top: 1%;
    width: 100%;
    z-index: auto;
    white-space: nowrap; /* Fuerza el contenido en una sola línea para que se desborde horizontalmente */
}

.scrollContainer {
    /*overflow-x: auto; /* Cambiado de scroll a auto para mostrar scrollbars solo cuando sea necesario */
    /*overflow-y: auto; /* Cambiado de scroll a auto para mostrar scrollbars solo cuando sea necesario */
    scrollbar-width: thin; /* Para Firefox: barra de desplazamiento fina */
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Color de la barra de desplazamiento en Firefox */
    max-width: 150%;
    max-height: 452px;
    width: 150%;
    z-index: auto;
    overflow-y: visible; /* Fuerza header fijo FIXME NO QUITAR VISIBLE*/
}

.scrollContainerQualityCoverage {
    overflow-x: auto; /* Cambiado de scroll a auto para mostrar scrollbars solo cuando sea necesario */
    overflow-y: auto; /* Cambiado de scroll a auto para mostrar scrollbars solo cuando sea necesario */
    scrollbar-width: thin; /* Para Firefox: barra de desplazamiento fina */
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Color de la barra de desplazamiento en Firefox */
    max-width: 100%;
    max-height: 452px;
    width: 100%;
    z-index: auto;
    white-space: nowrap; /* Fuerza el contenido en una sola línea para que se desborde horizontalmente */
}


.scrollContainer::-webkit-scrollbar {
    width: 12px; /* Ancho de la barra de desplazamiento en Chrome */
    height: 12px; /* Altura de la barra de desplazamiento en Chrome */
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* Color del pulgar de la barra de desplazamiento */
    border-radius: 10px; /* Redondea las esquinas del pulgar de la barra de desplazamiento */
}

.scrollContainer::-webkit-scrollbar-track {
    background: transparent; /* Color del fondo de la pista del scrollbar en Chrome */
}


.scroll-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Color de la pista de la barra de desplazamiento */
}

.point:hover{
    background-color: lightgray;
}

.modalLoading {
    position: absolute;
    font-size: 35px;
    background-color: white;
    top: 50%;
    left: 50%;
    padding: 30px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
}

.circularProgress {
    align-self: center;
}
